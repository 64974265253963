@import "_bootstrap";
@import "_colors";

// Styles

html {
  font-size: 16px;
}

body {
  background: $lgrey;
  font-family: "Roboto", Arial;
  font-size: 1rem;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-thickness: max(0.08em, 1px);
  text-underline-offset: 0.15em;
}

h1,
h2 {
  color: $dgrey;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
  font-weight: normal;
}

h3 {
  font-size: 24px;
  font-weight: normal;
}

h4 {
  font-weight: bold;
  margin-top: 1em;
}

blockquote {
  font: 12pt Courier;
  margin-top: 1em;
  white-space: pre-line;
}

p {
  line-height: 22px;
  text-align: left;
  text-indent: 2em;
}

img,
iframe {
  max-width: 100%;
}

.table {
  background: darken($blue, 5%);
  color: #fff;

  tr {
    &:nth-child(even) {
      background: lighten($blue, 5%);
    }

    td,
    th {
      border-color: #fff !important;
      padding: 4px 10px !important;
    }

    th {
      color: #fff;
      font-weight: normal;
    }
  }
}

.colors div {
  padding: 1em 0;
}

.header {
  width: 100%;
  margin: 0;
  border: 4px solid $green;
  border-width: 6px 0;
  background: #fff;
  text-align: center;

  @media (min-width: 1015px) {
    .header-break {
      display: none;
    }
  }

  img {
    display: inline-block;
    margin: 5px;
  }

  a.logo {
    display: inline-block;

    img {
      padding-left: 2rem;
    }
  }

  a:not(.logo) {
    display: inline-block;
    padding: 29px 10px;
    color: black;
    text-decoration: none;
    vertical-align: top;

    &:hover {
      color: #fff;
      background: #8dc63f;
    }

    @media (max-width: $screen-sm-max) {
      padding: 10px;
    }
    @media (min-width: $screen-md-min) {
      font-family: "Questrial";
      font-size: 14pt;
    }
  }

  @media (max-width: $screen-sm-max) {
    padding-bottom: 0;
  }
}

.body {
  @extend .blue;
  padding: 16px 6px;

  .container {
    width: 100%;
    max-width: 1170px;
    padding-inline: 1rem;
  }

  a {
    font-weight: 500;
    text-decoration-thickness: max(0.08em, 2px);

    &:hover, &:focus, &:active {
      color: $green;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    color: #fff;
    text-shadow: 0 1px 0 $dgrey;
  }

  .demo-button {
    @extend .orange, .t-white;
    display: table;
    margin: auto;
    padding: 9px 20px;
    border: 3px solid #fff;
    font-size: 20pt;
    border-radius: 3px;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.carousel {
  max-width: 545px;
  margin: auto;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);

  .carousel-caption {
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 8px 10px 25px 10px;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  .carousel-control {
    padding-top: 20%;
    font-size: 62pt;
    font-weight: bold;
    background-image: none;
    text-decoration: none;

    &.left {
      padding-left: 5px;
      text-align: left;
    }

    &.right {
      padding-right: 5px;
      text-align: right;
    }
  }

  .carousel-indicators {
    top: auto;
    bottom: -5px;
  }

  .item {
    height: 325px;
    padding-bottom: 50px;

    img {
      max-height: 325px;
      margin: 0 auto;
    }
  }
}

.content {
  padding: 1rem;
  color: white;
}

iframe {
  font: inherit;
  background-color: white;
}

.footer {
  padding: 1rem;
  line-height: 1.7;
  text-align: center;
  border-top: 1px solid $grey;
  color: $dgrey;
  background-color: $lgrey;

  .flex {
    align-items: center;
    margin-inline: auto;
    margin-block: 1rem;
  }

  p {
    margin-block: 1rem;
    text-align: center;
    text-indent: 0;
  }

  a {
    font-weight: 500;
    margin: 0;
  }

  img {
    display: block;
    max-width: 100%;
    margin-inline: auto;
  }

  iframe {
    display: block;
    margin: auto;
  }
}

.footer__statement {
  text-wrap: balance;
}

.footer__sponsors {
  margin-block: 2rem;
}
.footer__sponsor-link {
  display: block;
  padding: 1rem;

  &:hover {
    background-color: white;
  }
}
.footer__sponsor-img {
  max-height: 4rem;
  margin-inline: auto;
}

@media (min-width: 640px) {
  .footer__sponsors {
    display: flex;
    align-items: center;
  }
}

ul.support {
  padding-left: 40px;
  text-indent: -30px;
  list-style: none;

  li {
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  a {
    font-weight: bold;
  }
}

quote {
  display: block;
  padding: 1em 0;
  border-bottom: 1px dotted #fff;

  b {
    display: block;
    margin-bottom: 7px;
    font-weight: 500;
    color: #fff;
    text-align: justify;
  }

  span {
    display: block;
    text-align: right;
    color: lighten($green, 20%);
  }

  @media (min-width: 768px) {
    padding: 1rem;
  }
}

.video {
  padding: 6px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);

  a {
    color: $green;
  }

  iframe {
    display: block;
    margin: 6px auto;
  }
}

.newsfeed {
  .feed-item-title {
    margin: 5px 10px;
    font-size: 12pt;
    font-weight: normal;
  }

  .lead {
    margin-bottom: 5px;
  }

  .rss2html-note {
    font-size: 12px;
    opacity: 0.5;
  }
}

.header-announcement {
  padding: 1px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #212e0e;
  background-color: $green;
}

.header-announcement p {
  margin: 0;
  margin-bottom: 4px;
  text-align: center;
}

.header-announcement a {
  color: #212e0e;
  text-decoration: underline;
}

abbr[title] {
  text-decoration: none;
}

// Utilities

.w-content {
  width: 100%;
  max-width: fit-content;
}

@media (min-width: 64px) {
  .flex {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
.flex-1 {
  flex: 1 1 0%;
}

.nowrap {
  white-space: nowrap;
}

// Font Faces

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    local("Roboto"),
    local("Roboto-Regular"),
    url(https://fonts.gstatic.com/s/roboto/v15/2UX7WLTfW3W8TclTUvlFyQ.woff)
      format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src:
    local("Roboto Medium"),
    local("Roboto-Medium"),
    url(https://fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUT8E0i7KZn-EPnyo3HZu7kw.woff)
      format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src:
    local("Roboto Bold"),
    local("Roboto-Bold"),
    url(https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2)
      format("woff2");
}

@font-face {
  font-family: "Questrial";
  font-style: normal;
  font-weight: 400;
  src:
    local("Questrial"),
    local("Questrial-Regular"),
    url("/vufind/questrial.woff") format("woff"),
    url(https://fonts.gstatic.com/s/questrial/v5/MYWJ4lYm5dbZ1UBuYox79D8E0i7KZn-EPnyo3HZu7kw.woff)
      format("woff");
}
