$blue: #336e99;
$green: #8dc63f;
$orange: #ff8800;
$lgrey: #dddfdd;
$grey: #666766;
$dgrey: #333344;

.t-black {
  color: #000;
}
.t-white {
  color: #fff;
}

.blue {
  background: $blue;
  color: #fff;
}

.green {
  background: $green;
  color: #000;
}

.orange {
  background: $orange;
  color: #fff;
}

.lgrey {
  background: $lgrey;
  color: $grey;
}

.grey {
  background: $grey;
  color: #fff;
}

.dgrey {
  background: $dgrey;
  color: #fff;
}
